import * as styles from './dynamic-examples.module.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import {
  faArrowRight,
  faBars,
  faBook,
  faBrowser,
  faCommentAlt,
  faGlobe,
  faHandshake,
  faSearch,
  faShoppingCart,
} from '@fortawesome/pro-regular-svg-icons';
import { graphql, useStaticQuery } from 'gatsby';

import Brand from '../brand';
import Button from '../button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EXAMPLES = [
  {
    industry: 'Coaching',
    brandId: 'jessicabaum',
    screenshotIndex: 3,
    icon: faCommentAlt,
  },
  {
    industry: 'Service Provider',
    brandId: 'bobbyburk',
    screenshotIndex: 1,
    icon: faHandshake,
  },
  {
    industry: 'E-Commerce',
    brandId: 'suntouched',
    screenshotIndex: 5,
    icon: faShoppingCart,
  },
  {
    industry: 'Education',
    brandId: 'purdue',
    screenshotIndex: 4,
    icon: faBook,
  },
  {
    industry: 'Media',
    brandId: 'camillestyles',
    screenshotIndex: 2,
    icon: faBrowser,
  },
  {
    industry: 'B2B',
    brandId: 'atlassian',
    screenshotIndex: 0,
    icon: faGlobe,
  },
];

const DynamicExamples = () => {
  const data = useStaticQuery(graphql`
    query {
      screenshots: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "screenshots" }
        }
        sort: { fields: relativePath }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(width: 575, quality: 100)
            }
          }
        }
      }
    }
  `);
  const screenshots = data.screenshots.edges;

  const [currentExample, setCurrentExample] = useState();
  const [isActivated, setIsActivated] = useState(false);

  function handleSelectIndustry(example) {
    setIsActivated(true);
    setCurrentExample(example);
  }

  return (
    <div className={styles.examplesContainer}>
      <div className="row no-gutters">
        <div className="col-lg-8">
          <div className={styles.example} style={{ filter: !isActivated ? 'blur(3px)' : 'none' }}>
            <div className={styles.mockHeader}>
              <div className={styles.mockHeaderLogo}>
                <FontAwesomeIcon icon={faBars} />
                <Brand
                  id={!isActivated ? EXAMPLES[0].brandId : currentExample.brandId}
                  className={styles.brand}
                />
              </div>
              <div className={styles.mockHeaderButtons}>
                <FontAwesomeIcon icon={faSearch} />
              </div>
            </div>
            <div className={styles.screenshotContainer}>
              <div>
                <GatsbyImage
                  image={getImage(
                    screenshots[
                      !isActivated ? EXAMPLES[0].screenshotIndex : currentExample.screenshotIndex
                    ].node
                  )}
                  alt={`${!isActivated ? EXAMPLES[0].brandId : currentExample.brandId} screenshot`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className={styles.industriesContainer}>
            <p className="text-body" style={{ marginBottom: '0', fontWeight: '500' }}>
              What industry are you in?
            </p>
            <div className={styles.industryCards}>
              {EXAMPLES.map(({ industry, icon }, index) => (
                <button
                  key={index}
                  className={`${currentExample?.industry === industry ? styles.selected : ''}`}
                  onClick={() => handleSelectIndustry(EXAMPLES[index])}>
                  <FontAwesomeIcon icon={icon} className={styles.icon} />
                  {industry}
                </button>
              ))}
            </div>
            <Button
              href="https://ai.tryinteract.com/create"
              theme="primary"
              style={{ gap: '8px', marginTop: '1rem' }}>
              Make my quiz <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicExamples;
