// extracted by mini-css-extract-plugin
export var brand = "dynamic-examples-module--brand--6d53a";
export var example = "dynamic-examples-module--example--7cb46";
export var examplesContainer = "dynamic-examples-module--examples-container--2404e";
export var icon = "dynamic-examples-module--icon--0ab5d";
export var industriesContainer = "dynamic-examples-module--industries-container--a34db";
export var industryCards = "dynamic-examples-module--industry-cards--9c1f2";
export var industryHeader = "dynamic-examples-module--industry-header--957f4";
export var mockHeader = "dynamic-examples-module--mock-header--25bbb";
export var mockHeaderButtons = "dynamic-examples-module--mock-header-buttons--f8528";
export var mockHeaderLogo = "dynamic-examples-module--mock-header-logo--f7862";
export var screenshotContainer = "dynamic-examples-module--screenshot-container--f53ad";
export var selected = "dynamic-examples-module--selected--dab95";